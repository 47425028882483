import { Splide } from "@splidejs/splide";
import { URLHash } from "@splidejs/splide-extension-url-hash";
import { Video } from "@splidejs/splide-extension-video";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Intersection } from "@splidejs/splide-extension-intersection";

window.AutoScroll = AutoScroll;
window.URLHash = URLHash;
window.Intersection = Intersection;
window.Video = Video;
window.Splide = Splide;

import "@splidejs/splide/css";
import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";
